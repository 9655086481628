import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'gatsby';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

import RightArrow from '@assets/blog-category/right-arrow';
import CopyLink from '@assets/social-icons/copy-link';
import Facebook from '@assets/social-icons/facebook';
import Twitter from '@assets/social-icons/twitter';
import Linkedin from '@assets/social-icons/linkedin';
import RelatedBooks from './related-books';
import ScrollTracker from '../scroll-tracker/scroll-tracker';
import Seo from '@layouts/common/seo';
import PostSlices from '../blog/slices/postslices';
import ShareIcon from '@assets/blog/svg-icons/share-icon';
import Backdrop from '../backdrop/backdrop';
import LanguageContext from '../../context/language-context';

const BlogDetailsCompnent = ({ slices, data }) => {
  const { contentLanguage } = useContext(LanguageContext);
  const { title, excerpt, featuredImage, relatedAuthor, postedOn, tags } = data.prismicBlogDetails.data;
  const authortTitle = relatedAuthor?.document?.data.title;
  let copiedUrl = typeof window !== 'undefined' && window.location && window?.location?.href;
  const copyLinkUrl = () => {
    if (typeof window !== 'undefined' && window.location) {
      /* Copy the text inside the text field */

      navigator.clipboard.writeText(copiedUrl);
    }
  };

  const [showCopyToClipBoard, setShowCopyToClipBoard] = useState(false);
  const [showShareMenu, setShowShareMenu] = useState(false);

  let totalHeight;
  let winHeight;
  let diff;
  let percentScrolled = 1;
  let totalDiff;

  const handleTracker = () => {
    percentScrolled = Math.floor((window.scrollY / totalDiff) * 100);

    document.getElementById('indicator').style.width = `${percentScrolled}vw`;
  };

  function avoidScroll() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }

  function enableScroll() {
    window.onscroll = function () {};
  }

  useEffect(() => {
    totalHeight = document.body.offsetHeight;
    winHeight = window.innerHeight;
    diff = totalHeight - winHeight;
    const footer = document.getElementById('footer-body');
    totalDiff = diff - footer.offsetHeight;
    document.getElementById('languageButton').style.display = 'none';

    window.addEventListener('scroll', handleTracker);

    return () => {
      window.removeEventListener('scroll', handleTracker);
    };
  }, [handleTracker]);

  const relatedBooks = data.prismicBlogDetails.data?.similarBooks;

  return (
    <>
      <Seo />
      <ScrollTracker />

      <div className="blog-details-holder common-container u-margin-bottom-md u-margin-top-md ">
        <div className="blog-details-holder__image ">
          <img src={featuredImage.fluid.src} alt="opening book" />
        </div>
        <div className="blog-details-holder__description">
          <div className="blog-details-container">
            <div className="blog-details-holder__nested-button">
              <div className="blog-details-option">
                <Link to="/">
                  <h6 className="low-opacity">{contentLanguage === 'eng' ? 'Home' : 'Hogar'}</h6>
                </Link>
                <span className="blog-details-arrow">
                  <RightArrow />
                </span>
              </div>
              <div className="blog-details-option">
                <Link to="/blog">
                  <h6 className="low-opacity">{contentLanguage === 'eng' ? 'Knowledge' : 'Conocimiento'}</h6>
                </Link>
                <span className="blog-details-arrow">
                  <RightArrow />
                </span>
              </div>
              <div className="blog-details-option">
                <h6 className="bold">{contentLanguage === 'eng' ? 'Blog Details' : 'Detalles del blog'}</h6>
              </div>
            </div>
            <div className="blog-details__heading">
              <h1>{title.text} </h1>
            </div>
            <div className="blog-details__summary">{excerpt.text}</div>
            <div className="blog-details__info">
              {!authortTitle?.text && <div className="blog-details-category marginl-0">{tags?.text}</div>}

              {authortTitle?.text && (
                <div className="blog-details-author">
                  <h5>{'By : ' + authortTitle?.text}</h5>
                </div>
              )}

              <div className="posted-date-category">
                <div className="blog-details-posted-date"> {postedOn}</div>
                {authortTitle?.text && <div className="blog-details-category">{tags?.text}</div>}
              </div>
              <div
                className="share-icon-container"
                onClick={() => {
                  setShowShareMenu(true);
                  avoidScroll();
                }}
              >
                <ShareIcon />

                <div className={showShareMenu ? 'blog-details-share-to modified-share-menu' : 'hide'}>
                  <div className="share-to-options">
                    <h5>Share to</h5>
                  </div>

                  <FacebookShareButton url={copiedUrl}>
                    <div className="share-to-options">
                      <Facebook color="black" />

                      <div className="social-option">Facebook</div>
                    </div>
                  </FacebookShareButton>

                  <TwitterShareButton url={copiedUrl}>
                    <div className="share-to-options">
                      <Twitter color="black" /> <div className="social-option">Twitter</div>
                    </div>
                  </TwitterShareButton>
                  <LinkedinShareButton url={copiedUrl}>
                    <div className="share-to-options">
                      <Linkedin color="black" /> <div className="social-option">Linkedin</div>
                    </div>
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
          </div>
          <div className="blog-details-body  u-margin-top-xs">
            <div className="rich-text-holder">
              <PostSlices slices={slices} />
            </div>

            {/* <div className="blog-details-body-text">
              <div className="blog-details-body-about">
                <p>{textparagraph}</p>
              </div>
              <div className="blog-details-body1">
                <div className="blog-body1-heading">
                  <h4>{dummyHeading}</h4>
                </div>
                <div className="blog-body1-text">{textparagraph}</div>
              </div>
              <div className="blog-details-body2">
                <div className="blog-body1-heading">
                  <h4>{dummyHeading}</h4>
                </div>
                <div className="blog-body2-text blog-body1-text">
                  {textparagraph}
                </div>
                <div className="blog-body2-image">
                  <img src={image} alt="nature " />
                </div>
                <div className="blog-body2-text blog-body1-text">
                  {textparagraph}
                </div>
              </div>
            </div> */}

            <div className="blog-details-share-to">
              <div className={showCopyToClipBoard ? 'copied-to-clipboard' : 'hide'}>
                &#x2713;<h6>Copied to clipboard.</h6>
              </div>
              <div className="share-to-options">
                <h5>Share to</h5>
              </div>
              <div
                className="share-to-options"
                onClick={() => {
                  copyLinkUrl();
                  setShowCopyToClipBoard(true);
                  setTimeout(() => setShowCopyToClipBoard(false), 1000);
                }}
              >
                <CopyLink />
                <div className="social-option">Copy Link</div>
              </div>

              <FacebookShareButton url={copiedUrl}>
                <div className="share-to-options">
                  <Facebook color="black" />

                  <div className="social-option">Facebook</div>
                </div>
              </FacebookShareButton>

              <TwitterShareButton url={copiedUrl}>
                <div className="share-to-options">
                  <Twitter color="black" /> <div className="social-option">Twitter</div>
                </div>
              </TwitterShareButton>
              <LinkedinShareButton url={copiedUrl}>
                <div className="share-to-options">
                  <Linkedin color="black" /> <div className="social-option">Linkedin</div>
                </div>
              </LinkedinShareButton>
            </div>
          </div>
        </div>

        {relatedBooks && <RelatedBooks books={relatedBooks} from="blogs" />}
      </div>
      <div
        className={showShareMenu ? 'show' : 'hide'}
        onClick={() => {
          setShowShareMenu(false);
          enableScroll();
        }}
      >
        <Backdrop />
      </div>
    </>
  );
};
export default BlogDetailsCompnent;

import React from 'react';

export default function ShareIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 8.17505C19.6569 8.17505 21 6.8319 21 5.17505C21 3.51819 19.6569 2.17505 18 2.17505C16.3431 2.17505 15 3.51819 15 5.17505C15 6.8319 16.3431 8.17505 18 8.17505Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 15.175C7.65685 15.175 9 13.8319 9 12.175C9 10.5182 7.65685 9.17505 6 9.17505C4.34315 9.17505 3 10.5182 3 12.175C3 13.8319 4.34315 15.175 6 15.175Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 22.175C19.6569 22.175 21 20.8319 21 19.175C21 17.5182 19.6569 16.175 18 16.175C16.3431 16.175 15 17.5182 15 19.175C15 20.8319 16.3431 22.175 18 22.175Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.59003 13.6851L15.42 17.6651"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.41 6.68506L8.59003 10.6651"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

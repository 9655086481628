import React from 'react';
import { graphql } from 'gatsby';

import BlogDetailsCompnent from '@components/blog-details/blog-details-page';
import Layout from '@layouts';
import Seo from '@layouts/common/seo';
import { Helmet } from 'react-helmet';

export default function BlogDetailsPage({ data }) {
  const metaTitle = data.prismicBlogDetails?.data?.metaTitle?.text;
  const title = data.prismicBlogDetails?.data?.title?.text;
  const seoImage = data.prismicBlogDetails?.data?.featuredImage?.fixed?.src;
  const uid = data.prismicBlogDetails?.uid;
  const metaDescription = data.prismicBlogDetails?.data?.metaDescription?.text;
  let url = process.env.CLIENT_URL + '/blog/' + uid;

  return (
    <>
      <Seo title={metaTitle ?? title} description={metaDescription} siteUrl={url} seoImage={seoImage} />
      <Layout>
        <BlogDetailsCompnent slices={data.prismicBlogDetails.data?.body} data={data} />
      </Layout>
    </>
  );
}

export const query = graphql`
  query signle($uid: String) {
    prismicBlogDetails(uid: { eq: $uid }) {
      id
      uid
      data {
        featuredImage: featured_image {
          fluid {
            src
          }
        }
        title {
          text
        }
        metaTitle: meta_title {
          text
        }
        metaDescription: meta_description {
          text
        }
        featuredImage: featured_image {
          fixed {
            src
          }
        }
        excerpt {
          text
        }
        relatedAuthor: related_author {
          document {
            ... on PrismicAuthor {
              id
              data {
                title {
                  text
                }
              }
            }
          }
        }
        postedOn: posted_on(fromNow: true)
        blog_categories {
          category {
            document {
              ... on PrismicBlogCategories {
                id
                data {
                  category {
                    text
                  }
                }
              }
            }
          }
        }
        tags {
          text
        }
        body {
          ... on PrismicBlogDetailsBodyPhoto {
            id
            slice_type
            primary {
              blog_images {
                fluid {
                  src
                }
              }
            }
          }
          ... on PrismicBlogDetailsBodyText {
            slice_type
            primary {
              blog_text {
                raw
              }
            }
            id
          }
        }
        similarBooks: similar_books {
          similarBook: similar_book {
            document {
              ... on PrismicBooks {
                id
                data {
                  bookInformation: book_info {
                    text
                    raw
                  }
                  coverPhoto: cover_image {
                    fluid {
                      src
                    }
                  }
                  price {
                    text
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

import React from 'react';

import Image from './image';
import Text from './text';

const PostSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case 'text':
          return (
            <div key={index} className="blogpage-slice-wrapper">
              {<Text slice={slice} />}
            </div>
          );
        case 'photo':
          return (
            <div key={index} className="blogpage-slice-wrapper">
              {<Image slice={slice} />}
            </div>
          );
        default:
          return;
      }
    })();
    return res;
  });
};

export default PostSlices;
